<template>
  <Component :is="resCom" />
</template>

<script setup lang="ts">
definePageMeta({
  layout: "home"
})

const resultCom = await import("../pages/home/index.vue")
const resCom = shallowRef(resultCom?.default)
</script>

<style scoped></style>
